import React, { SVGProps } from 'react';
import { styled } from '@mui/material/styles';

const Path = styled('path')<{ heatmapcolourname: string | null }>(
  ({ heatmapcolourname, theme: { palette } }) => ({
    fill: heatmapcolourname
      ? palette.heatmap[
          heatmapcolourname as unknown as keyof typeof palette.heatmap
        ].currentStreak
      : palette.heatmap.amber.currentStreak,
  })
);

interface EventUpcomingImageProps extends SVGProps<SVGSVGElement> {
  heatmapcolourname: string | null;
}

const EventUpcomingImage = ({
  heatmapcolourname,
  ...props
}: EventUpcomingImageProps): JSX.Element => (
  <svg
    {...props}
    fill="none"
    height="24"
    viewBox="0 0 25 24"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      height="24"
      id="mask0_10620_106775"
      maskUnits="userSpaceOnUse"
      width="25"
      x="0"
      y="0"
    >
      <rect fill="#D9D9D9" height="24" width="24" x="0.5" />
    </mask>
    <g mask="url(#mask0_10620_106775)">
      <Path
        d="M15.5 22V20H19.5V10H5.5V14H3.5V6C3.5 5.45 3.69583 4.97917 4.0875 4.5875C4.47917 4.19583 4.95 4 5.5 4H6.5V2H8.5V4H16.5V2H18.5V4H19.5C20.05 4 20.5208 4.19583 20.9125 4.5875C21.3042 4.97917 21.5 5.45 21.5 6V20C21.5 20.55 21.3042 21.0208 20.9125 21.4125C20.5208 21.8042 20.05 22 19.5 22H15.5ZM8.5 24L7.1 22.6L9.675 20H1.5V18H9.675L7.1 15.4L8.5 14L13.5 19L8.5 24ZM5.5 8H19.5V6H5.5V8Z"
        heatmapcolourname={heatmapcolourname}
      />
    </g>
  </svg>
);

export default EventUpcomingImage;
