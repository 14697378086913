import React, { SVGProps } from 'react';
import { styled } from '@mui/material/styles';

const Path = styled('path')<{ heatmapcolourname: string | null }>(
  ({ heatmapcolourname, theme: { palette } }) => ({
    fill: heatmapcolourname
      ? palette.heatmap[
          heatmapcolourname as unknown as keyof typeof palette.heatmap
        ].avgQuestions
      : palette.heatmap.amber.avgQuestions,
  })
);

interface NumbersImageProps extends SVGProps<SVGSVGElement> {
  heatmapcolourname: string | null;
}

const NumbersImage = ({
  heatmapcolourname,
  ...props
}: NumbersImageProps): JSX.Element => (
  <svg
    {...props}
    fill="none"
    height="24"
    viewBox="0 0 25 24"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      height="24"
      id="mask0_10620_106793"
      maskUnits="userSpaceOnUse"
      width="25"
      x="0"
      y="0"
    >
      <rect fill="#D9D9D9" height="24" width="24" x="0.5" />
    </mask>
    <g mask="url(#mask0_10620_106793)">
      <Path
        d="M6.5 20L7.5 16H3.5L4 14H8L9 10H5L5.5 8H9.5L10.5 4H12.5L11.5 8H15.5L16.5 4H18.5L17.5 8H21.5L21 10H17L16 14H20L19.5 16H15.5L14.5 20H12.5L13.5 16H9.5L8.5 20H6.5ZM10 14H14L15 10H11L10 14Z"
        heatmapcolourname={heatmapcolourname}
      />
    </g>
  </svg>
);

export default NumbersImage;
