import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import { localStorageTyped } from 'utils';

type DashboardWidgetType = 'heatmap' | 'daily-streak';

interface DashboardWidgetState {
  dashboardWidget: DashboardWidgetType;
  setDashboardWidget: (value: DashboardWidgetType) => void;
}

const { getItem, setItem } =
  localStorageTyped<DashboardWidgetType>('widgetChoice');

const dashboardWidgetState = create<DashboardWidgetState>()(set => ({
  dashboardWidget: getItem() || 'heatmap',
  setDashboardWidget: dashboardWidget => {
    setItem(dashboardWidget);
    set({ dashboardWidget });
  },
}));

const useDashboardWidget = () =>
  dashboardWidgetState(
    useShallow(({ dashboardWidget, setDashboardWidget }) => ({
      dashboardWidget,
      setDashboardWidget,
    }))
  );

export default useDashboardWidget;
