import { IHighlightNode } from '@quesmed/types-rn/models';
import html2md from 'html-to-md';
import markdownToTxt from 'markdown-to-txt';

import { MIN_SELECTION_LENGTH } from 'config/constants';
import { adjustHighlightData } from './adjustHighlightData';
import { extractHTMLContentFromElement } from './extractHTMLContentFromElement';
import { getPositions } from './getPositions';
import { isSpecialCharacter } from './isSpecialCharacter';
import { isBackwardsSelection } from './isBackwardsSelection';
import { selectionIsEmpty } from './selectionIsEmpty';
import { getRootElement } from './getRootElement';

export interface IHandleSelection {
  highlights: IHighlightNode[];
  fullMarkdown: string;
}

export const selectSingleLine = (
  selection: Selection,
  fullMarkdown: string,
  selectedMarkdown: string,
  selectedText: string,
  range: Range
): IHighlightNode[] | null => {
  let anchorText = selection.anchorNode as Node;
  if (isBackwardsSelection(selection)) {
    anchorText = selection?.focusNode as Node;
  }
  let selectionRootElement = anchorText.parentElement as Node;

  if (anchorText?.parentElement?.nodeName !== 'P') {
    selectionRootElement = getRootElement(anchorText?.parentElement as Node);
  }

  let content = extractHTMLContentFromElement(selectionRootElement); // whole root paragraph content in string format
  if (selectionRootElement.nodeName !== 'P') {
    content = `<${selectionRootElement.nodeName.toLowerCase()}>${content}</${selectionRootElement.nodeName.toLowerCase()}>`;
  }

  return getPositions(
    fullMarkdown,
    html2md(content, { skipTags: ['mark'] }).replaceAll('>\\', '>'),
    selectedMarkdown,
    selectedText,
    selectionRootElement,
    range
  );
};

export const selectMultipleLine = (
  fullMarkdown: string,
  selectedMarkdown: string
): IHighlightNode[] | null => {
  const lines = selectedMarkdown.split('\n');
  const newHighlights = [];
  let startedAt = 0;
  for (const line of lines) {
    if (!line || line.length <= MIN_SELECTION_LENGTH) continue;
    if (line.includes('![Image]')) continue; // string contain image
    if (line.match(/^[^a-zA-Z0-9]+$/)) continue; // string contain no alphanumeric, solves table issue
    let start = fullMarkdown.indexOf(line, startedAt);

    if (start === -1) {
      start = fullMarkdown.indexOf(
        line.length > 2 ? line.slice(2, line.length - 1) : line.slice(0, 2),
        startedAt
      );

      if (start === -1) continue;

      start = start - (line.length > 2 ? 2 : 0);
    }

    const end = start + line.length - 1;

    let removedMarkdownElementsLength = 0;
    const isChar1Special = isSpecialCharacter(line[0]);
    const isChar2Special = isSpecialCharacter(line[1]);

    if (
      (isChar1Special && !isChar2Special) ||
      (isChar1Special && isChar2Special)
    ) {
      const plainText = markdownToTxt(line) || '';
      removedMarkdownElementsLength = line.indexOf(plainText.slice(0, 2));
    }

    if (line.includes('|')) {
      const items = line.split('|').filter(item => item !== '');
      items.map(itemText => {
        const start = fullMarkdown.indexOf(itemText);
        newHighlights.push({
          start: start + removedMarkdownElementsLength,
          end: start + itemText.length - 1,
          text: itemText,
        });
      });
    } else {
      newHighlights.push({
        start: start + removedMarkdownElementsLength,
        end,
        text: line,
      });
    }

    startedAt = end;
  }

  return newHighlights;
};

export const selectText = ({ highlights, fullMarkdown }: IHandleSelection) => {
  const newHighlights = [...highlights];
  const selection = window.getSelection() as Selection;
  if (selectionIsEmpty(selection)) return null;
  const selectedText = selection.toString() as string;
  if (selectedText?.length <= 1) return null;

  const range = selection.getRangeAt(0);
  const cloneContents = range.cloneContents();
  const selectedHtml = extractHTMLContentFromElement(cloneContents);
  const selectedMarkdown = html2md(selectedHtml, { skipTags: ['mark'] })
    .replaceAll('>\\', '>')
    .replace(/([ \w])([*])([\w ])/g, '$1_$3'); // replace incorrect parsing of italic;;

  if (
    selectedText.includes('\n') ||
    selectedText.includes('|') ||
    selectedMarkdown.includes('|')
  ) {
    const positions = selectMultipleLine(fullMarkdown, selectedMarkdown);
    if (!positions) {
      return null;
    }
    newHighlights.push(...positions);
  } else {
    const positions = selectSingleLine(
      selection,
      fullMarkdown,
      selectedMarkdown,
      selectedText,
      range
    );

    if (!positions) {
      return null;
    }
    newHighlights.push(...positions);
  }

  const final = adjustHighlightData(newHighlights, fullMarkdown);
  window.getSelection()?.empty();

  return final;
};
