import { useEffect } from 'react';

import { logError } from 'utils';

const config = { attributes: true };
const IMAGE_CLASS = 'yarl__slide_image';
const BODY_CLASS = 'yarl__no_scroll';

const useLightboxImagesDownloadDisable = () => {
  useEffect(() => {
    const body = document.querySelector('body');

    let mutationObserver: MutationObserver;

    const handleContextMenu = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      const lightboxImage =
        target.nodeName === 'IMG' && target.classList.contains(IMAGE_CLASS);

      if (lightboxImage) {
        e.preventDefault();
      }
    };

    try {
      mutationObserver = new MutationObserver(mutationList => {
        mutationList.forEach(mutation => {
          const { attributeName, target } = mutation;

          if (mutation.type === 'attributes' && attributeName === 'class') {
            if ((target as HTMLElement).classList.contains(BODY_CLASS)) {
              document.addEventListener('contextmenu', handleContextMenu);
            } else {
              document.removeEventListener('contextmenu', handleContextMenu);
            }
          }
        });
      });

      if (body) {
        mutationObserver.observe(body, config);
      }
    } catch (error) {
      logError(error);
    }

    return () => {
      mutationObserver.disconnect();
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);
};
export default useLightboxImagesDownloadDisable;
