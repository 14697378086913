import React from 'react';
import { mdiLayersOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const LayersOutlineIcon = (props: IconProps) => (
  <Icon path={mdiLayersOutline} {...props} />
);

export default LayersOutlineIcon;
