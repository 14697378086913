export const cutText = (text: string, maxLength = 8, suffix = '...') => {
  const trimmed = text.trim();
  if (!trimmed.length) return '';

  return trimmed.length > maxLength
    ? `${text.substr(0, maxLength)}${suffix}`
    : text;
};

// TODO: to be reworked so that the direct URI is stored in the DB.
export const sliceUri = (path: string) => path?.slice(7, path.length);
