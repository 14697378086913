import React, { useMemo, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { IPicture } from '@quesmed/types-rn/models';
import {
  SlideImage,
  Lightbox as YetLightbox,
} from 'yet-another-react-lightbox';
import {
  Captions,
  Fullscreen,
  Thumbnails,
  Zoom,
} from 'yet-another-react-lightbox/plugins';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

import { IMAGE_URL } from 'config/constants';
import useLightboxState from './useLightboxState';
import { sliceUri } from 'utils/index';
import useLightboxImagesDownloadDisable from './useLightboxImagesDownloadDisable';
import { LayersOffOutlineIcon, LayersOutlineIcon } from 'components/Icons';
import { Image } from './LightboxImage';
import { ToggleButton } from 'components/ToggleButton';

const LIGHTBOX_ANIMATION_DURATION = 500;

const Slide = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(2),
  alignItems: 'center',
}));

const OverlayButton = styled(ToggleButton)(({ theme: { breakpoints } }) => ({
  '&.overlay-button': {
    position: 'absolute',
    top: 0,
    left: 0,

    [breakpoints.up('md')]: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
}));

export interface LightboxProps {
  pictures?: IPicture[];
  showCaptions?: boolean;
}

interface SlideImageWithOverlay extends SlideImage {
  overlaySrc: string;
}

const Lightbox = ({
  pictures,
  showCaptions = true,
}: LightboxProps): JSX.Element => {
  const [isOverlay, setIsOverlay] = useState(false);
  const { palette, typography } = useTheme();
  const { closeLightbox, index, setIndex } = useLightboxState();

  const slides = useMemo(
    () =>
      pictures?.map(
        ({ caption, name, path, overlayPath }) =>
          ({
            description: showCaptions ? caption : undefined,
            name,
            src: `${IMAGE_URL}${sliceUri(path)}`,
            overlaySrc: `${IMAGE_URL}${sliceUri(overlayPath)}`,
          } as SlideImageWithOverlay)
      ),
    [pictures, showCaptions]
  );

  const toggleIsOverlay = () => {
    setIsOverlay(prev => !prev);
  };

  const preload = slides?.length ? Math.floor((slides?.length ?? 0) / 2) : 0;

  useLightboxImagesDownloadDisable();

  return (
    <YetLightbox
      animation={{
        fade: LIGHTBOX_ANIMATION_DURATION,
        zoom: LIGHTBOX_ANIMATION_DURATION,
      }}
      captions={{ descriptionTextAlign: 'center' }}
      carousel={{ preload }}
      close={closeLightbox}
      controller={{ closeOnBackdropClick: true }}
      index={index}
      on={{
        view: ({ index }) => {
          setIndex(index);
          setIsOverlay(false);
        },
      }}
      open={index >= 0}
      plugins={[Captions, Fullscreen, Thumbnails, Zoom]}
      render={{
        slide: ({ slide }) => {
          const typedSlide = slide as SlideImageWithOverlay;

          if (pictures?.[index]?.overlayPath) {
            return (
              <Slide>
                <OverlayButton
                  className="overlay-button"
                  icon={
                    isOverlay ? <LayersOffOutlineIcon /> : <LayersOutlineIcon />
                  }
                  label={`${isOverlay ? 'Hide' : 'Show'} Overlay`}
                  onChange={toggleIsOverlay}
                  selected={isOverlay}
                  value="overlay"
                />
                <Image
                  className="lightbox-image"
                  src={isOverlay ? typedSlide.overlaySrc : typedSlide.src}
                />
              </Slide>
            );
          }

          return null;
        },
      }}
      slides={slides}
      styles={{
        captionsDescription: {
          ...typography.body1Medium,
        },
        root: {
          '--yarl__thumbnails_thumbnail_border': '1px solid transparent',
          '--yarl__color_button_active': `${palette.common.white}`,
        },
      }}
      thumbnails={{
        position: 'bottom',
        border: 1,
        borderRadius: 4,
        padding: 4,
        gap: 4,
        imageFit: 'cover',
      }}
      zoom={{
        maxZoomPixelRatio: 8,
        zoomInMultiplier: 2,
        scrollToZoom: true,
        doubleClickDelay: 300,
      }}
    />
  );
};

export default Lightbox;
