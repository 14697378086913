import { EMockTestType } from '@quesmed/types-rn/models';

import { MockTestCategory } from 'types/qbank';

const MAP = {
  [EMockTestType.ALL]: MockTestCategory.ALL,
  [EMockTestType.ANATOMY_SPOTTER]: MockTestCategory.AnatomySpotter,
  [EMockTestType.UKMLA]: MockTestCategory.UKMLA,
  [EMockTestType.UKMLA_FULL]: MockTestCategory.UKMLA_FULL,
  [EMockTestType.FINALS]: MockTestCategory.Finals,
  [EMockTestType.MISCELLANEOUS]: MockTestCategory.Miscellaneous,
  [EMockTestType.PSA]: MockTestCategory.PSA,
  [EMockTestType.UNIVERSITY_SPECIFIC]: MockTestCategory.UniversitySpecific,
  [EMockTestType.MRCP]: MockTestCategory.MRCP,
};

export const getTestCategoryName = (type: EMockTestType) => MAP[type];
