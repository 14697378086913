import React from 'react';
import { mdiLayersOffOutline } from '@mdi/js';

import Icon, { IconProps } from './Icon';

const LayersOffOutlineIcon = (props: IconProps) => (
  <Icon path={mdiLayersOffOutline} {...props} />
);

export default LayersOffOutlineIcon;
