import React, { MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import { IPicture } from '@quesmed/types-rn/models';

import { constSize, isUrl, parseJSON } from 'utils';
import { ZoomIcon } from 'components/Icons';
import {
  LARGE_IMAGE_SIZE,
  NORMAL_IMAGE_SIZE,
  SMALL_IMAGE_SIZE,
} from 'config/constants';
import useLightboxState from './useLightboxState';
import { OutsideLink } from 'components/Link';
import { Body } from 'components/Typography';

const MAX_IMAGE_WIDTH = '800px';
const MAX_IMAGE_HEIGHT = '600px';

const IconBox = styled('span')(({ theme: { palette } }) => ({
  color: palette.background.paper,
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  ...constSize('28px'),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& .MuiSvgIcon-root': {
    transform: 'scale(1.35)',
  },
}));

const OverlayBackground = styled(Body)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.3)',
});

const OverlayText = styled(Body)(({ theme: { palette } }) => ({
  color: palette.primary.contrastText,
  position: 'absolute',
  top: '0',
  left: '50%',
  transform: 'translateX(-50%)',
  maxWidth: '200px',
  textAlign: 'center',
}));

const ImageContainer = styled('span')<{ size: number }>(
  ({ theme: { breakpoints, spacing } }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: `${spacing(6)} auto`,

    [breakpoints.up('md')]: {
      margin: `${spacing(8)} auto`,
    },
  })
);

const ImageBox = styled('span')(
  ({ theme: { shape, spacing, transitions } }) => ({
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    boxSizing: 'border-box',
    justifyContent: 'center',
    width: '100%',
    maxWidth: MAX_IMAGE_WIDTH,
    maxHeight: MAX_IMAGE_HEIGHT,
    borderRadius: shape.borderRadius,
    marginBottom: spacing(4),

    '& .icon-box, & .overlay-text, & .overlay-background': {
      opacity: 0,
      transition: transitions.create(['opacity']),
    },

    '&.with-lightbox': {
      cursor: 'pointer',

      '&:hover': {
        '.icon-box, .overlay-text, .overlay-background': {
          opacity: 1,
        },
      },
    },
  })
);

const ImageCaption = styled('span')(({ theme: { typography } }) => ({
  ...typography.body1Medium,
}));

export const Image = styled('img')({
  '&.lightbox-image': {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    maxWidth: MAX_IMAGE_WIDTH,
    maxHeight: MAX_IMAGE_HEIGHT,
  },
});

interface LightboxImageProps {
  caption?: string;
  index?: number;
  src: string;
  withLightbox: boolean;
  showCaption?: boolean;
}

interface ImageData
  extends Pick<IPicture, 'path256' | 'path512' | 'overlayPath'> {
  caption: string;
}

const parseImageData = (caption?: string): ImageData => {
  const initImageData: ImageData = {
    path256: '',
    path512: '',
    caption: '',
    overlayPath: '',
  };
  if (caption) {
    try {
      const parsedCaption = parseJSON<ImageData>(caption);

      if (parsedCaption) {
        return parsedCaption;
      }
    } catch {
      return initImageData;
    }
  }

  return initImageData;
};

export const LightboxImage = ({
  caption: sourceCaption,
  index = 0,
  src,
  showCaption,
  withLightbox,
}: LightboxImageProps): JSX.Element => {
  const { openLightbox } = useLightboxState();
  const { path256, path512, caption, overlayPath } =
    parseImageData(sourceCaption);
  const srcSet = `${path256 ? `${path256} ${SMALL_IMAGE_SIZE}w` : ''}${
    path512 ? `, ${path512} ${NORMAL_IMAGE_SIZE}w` : ''
  }${src ? `, ${src} ${LARGE_IMAGE_SIZE}w` : ''}`;

  const handleOpenLightbox = (index: number) => () => {
    openLightbox(index);
  };

  const handlePreventDownload = (event: MouseEvent<HTMLImageElement>) => {
    event.preventDefault();
  };

  return (
    <ImageContainer
      onClick={withLightbox ? handleOpenLightbox(index) : undefined}
      size={NORMAL_IMAGE_SIZE}
    >
      <ImageBox
        className={withLightbox ? 'with-lightbox' : ''}
        onContextMenu={handlePreventDownload}
      >
        <Image
          alt={showCaption ? caption : undefined}
          className="lightbox-image"
          onContextMenu={handlePreventDownload}
          src={src}
          srcSet={srcSet}
          title={showCaption ? caption : undefined}
        />
        {withLightbox ? (
          <OverlayBackground className="overlay-background" />
        ) : null}
        {withLightbox && overlayPath ? (
          <OverlayText className="overlay-text">
            Click on image to view overlay
          </OverlayText>
        ) : null}
        {withLightbox ? (
          <IconBox className="icon-box">
            <ZoomIcon />
          </IconBox>
        ) : null}
      </ImageBox>
      {showCaption && caption ? (
        <ImageCaption>
          {isUrl(caption) ? (
            <OutsideLink href={caption}>Source</OutsideLink>
          ) : (
            caption
          )}
        </ImageCaption>
      ) : null}
    </ImageContainer>
  );
};

export default LightboxImage;
