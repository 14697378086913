import React, { ReactNode } from 'react';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';

import { TextField, TextInputProps } from 'components/TextField';

export type AutocompleteProps<T extends FieldValues, K> = Omit<
  ControllerProps<T>,
  'render'
> & {
  disableClearable?: boolean;
  helperText?: string;
  label?: ReactNode;
  fieldProps?: Omit<TextInputProps, 'label'>;
  value?: string | null;
  validator?: (value: any) => string | undefined;
  type?: string;
} & Omit<MuiAutocompleteProps<K, boolean, boolean, boolean>, 'renderInput'>;

export function AutocompleteField<T extends FieldValues, K>({
  options,
  control,
  defaultValue,
  disableClearable = true,
  helperText,
  label,
  fieldProps,
  name,
  rules,
  shouldUnregister,
  type,
  validator,
  ...autocompleteProps
}: AutocompleteProps<T, K>): JSX.Element {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({
        field: { onChange, value: fieldValue },
        fieldState: { error: formError },
      }) => {
        const { message } = formError || {};
        const error =
          message || (validator ? validator(fieldValue) : undefined);

        return (
          <MuiAutocomplete
            {...control?.register(name, { required: true })}
            defaultValue={defaultValue}
            disableClearable={disableClearable}
            filterOptions={autocompleteProps.filterOptions}
            onChange={(_, value) => {
              onChange(value);
            }}
            options={options}
            renderInput={params => (
              <TextField
                error={Boolean(error)}
                helperText={helperText || error}
                label={label}
                name={name}
                type={type || 'string'}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                {...fieldProps}
              />
            )}
            {...autocompleteProps}
            value={fieldValue || autocompleteProps?.value || null}
          />
        );
      }}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  );
}
