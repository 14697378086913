import { EDifficultyType, EProductType } from '@quesmed/types-rn/models';

import { FlashcardScore } from 'types/qbank';
import locales from 'locales';

export const DEV_APPS = ['https://dev.quesmed.com', 'http://localhost:3000'];

// ENVS
export const APP_URL = import.meta.env.VITE_APP_BASE_URL || '';
export const SERVER_BASE_URL = import.meta.env.VITE_APP_SERVER_BASE_URL;
export const SUBSCRIPTION_BASE_URL = import.meta.env
  .VITE_APP_SUBSCRIPTION_BASE_URL;
export const GQL_ENDPOINT = `${SERVER_BASE_URL}/graphql`;
export const SUBSCRIPTION_ENDPOINT = `${SUBSCRIPTION_BASE_URL}/graphql`;
export const STRIPE_KEY = import.meta.env.VITE_APP_STRIPE_KEY;
export const DEBUG = import.meta.env.VITE_APP_DEBUG === 'true' || false;
export const isDev = import.meta.env.VITE_APP_ENVIRONMENT === 'development';
export const AGORA_APP_ID = import.meta.env.VITE_APP_AGORA_APP_ID || '';
export const SENTRY_DNS = import.meta.env.VITE_APP_SENTRY_DNS || '';
export const ZOOM_API_KEY = import.meta.env.VITE_APP_ZOOM_JWT_API_KEY || '';
export const MEASUREMENT_ID = import.meta.env.VITE_APP_MEASUREMENT_ID || '';
export const CONVERSION_ID = import.meta.env.VITE_APP_CONVERSION_ID || '';
export const PIXEL_ID = import.meta.env.VITE_APP_PUBLIC_PIXEL_ID || '';
export const PUBLIC_ACCESS_KEY =
  import.meta.env.VITE_APP_PUBLIC_TOKEN_ACCESS_KEY || '';
export const IMAGE_URL = 'https://images.quesmed.com/';
export const USE_DEV = isDev || APP_URL?.includes('dev');

// EXTERNAL LINKS
export const termsAndConditionsLink =
  'https://quesmed.com/terms-and-conditions/';
export const privacyPolicyLink = 'https://quesmed.com/privacy-policy/';

export const SMALL_IMAGE_SIZE = 256;
export const NORMAL_IMAGE_SIZE = 512;
export const LARGE_IMAGE_SIZE = 768;
export const TRANSITION_TIMEOUT = 300;
export const CONTENT_MAX_WIDTH = 840;

export const MAX_UNIQUE_WORD_LENGTH = 15;
export const MIN_SELECTION_LENGTH = 2;
export const CURRENCY = '£';

export const MILISECONDS_IN_SECOND = 1000;
export const MILISECONDS_IN_DAY = 86400000; // 24 * 60 * 60 * 1000;
export const TWENTY_FOUR_HOURS = MILISECONDS_IN_DAY;

export const STORAGE_KEYS = {
  USER_NOTES: 'USER_NOTES',
};

export const ERRORS = {
  FAILED_TO_FETCH: 'Failed to fetch',
  GENERAL_ERROR:
    'Something went wrong. If this problem persists, please let us know.',
  CONNECTION_ERROR: 'Check internet connection.',
  GROUP_STUDY_ALREADY_STARTED:
    'You cannot join the group as the session has already started.',
  BUILD_MARKSHEET: 'Unable to build from marksheetId:',
  MARKSHEET_MARK_ALREADY_ANSWERED: 'MaksheetMark already answered',
  MARKSHEET_COMPLETED: 'Marksheet is completed.',
  TOKEN_NOT_PROVIDED:
    'Variable "$token" of required type "String!" was not provided.',
  SESSION_EXPIRED: 'Session expired.',
  SERVER_ERROR: 'Internal server error',
};

export const VALID_TOKEN_VERSION = 4;
export const INIT_TIMER = '00:00';
export const MAX_QUESTION_VALUE = 200;
export const MAX_SCORE = 100;
export const SECONDS_PER_HOUR = 60 * 60;
export const SECONDS_PER_MINUTE = 60;

export const DEFAULT_TEXT_SCALE = 1.3;

export const DIFFICULTY_OPTIONS = {
  [EDifficultyType.ALL]: 'All',
  [EDifficultyType.CORE]: 'Core',
  [EDifficultyType.CHALLENGING]: 'Medium',
  [EDifficultyType.DIFFICULT]: 'Hard',
  [EDifficultyType.QUICK_FIRE]: 'Quick Fire',
};

export const QUESTION_DIFFICULTY_LEVELS = [
  EDifficultyType.CORE,
  EDifficultyType.CHALLENGING,
  EDifficultyType.DIFFICULT,
];

export const classArray = [
  {
    value: 'Premed',
    label: 'Premed',
  },
  {
    value: 'BSc',
    label: 'BSc',
  },
  {
    value: 'MSc',
    label: 'MSc',
  },
  {
    value: 'PhD',
    label: 'PhD',
  },
  {
    value: 'Year 1',
    label: 'Year 1',
  },
  {
    value: 'Year 2',
    label: 'Year 2',
  },
  {
    value: 'Year 3',
    label: 'Year 3',
  },
  {
    value: 'Year 4',
    label: 'Year 4',
  },
  {
    value: 'Year 5',
    label: 'Year 5',
  },
  {
    value: 'Graduated',
    label: 'Graduated',
  },
];

const {
  flashcards: { score },
} = locales;

export const FLASHCARD_SCORES: { label: string; score: FlashcardScore }[] = [
  {
    label: score.one,
    score: FlashcardScore.NotAtAll,
  },
  {
    label: score.two,
    score: FlashcardScore.Slightly,
  },
  {
    label: score.three,
    score: FlashcardScore.Moderately,
  },
  {
    label: score.four,
    score: FlashcardScore.KnowWell,
  },
  {
    label: score.five,
    score: FlashcardScore.Perfectly,
  },
];

export const PRE_BUILD_DIFFICULTY_OPTIONS = [
  {
    id: EDifficultyType.CORE,
    label: 'Core',
    value: EDifficultyType.CORE,
    infoTip:
      'Suitable for early year students developing a grounding in clinical medicine or later year students wishing to revise key material.',
  },
  {
    id: EDifficultyType.CHALLENGING,
    label: 'Medium',
    value: EDifficultyType.CHALLENGING,
    infoTip:
      'For those with more established experience in clinical medicine, but can be attempted by all those wishing to push themselves and learn deeply!',
  },
  {
    id: EDifficultyType.DIFFICULT,
    label: 'Hard',
    value: EDifficultyType.DIFFICULT,
    infoTip:
      "Ranging from advanced clinical reasoning to more niche knowledge. Don't worry too much about your scores as everyone else will find them difficult!",
  },
];

export const FLASHCARDS_PRODUCTS = [
  EProductType.QBANK,
  EProductType.ANATOMY,
  EProductType.MEDICAL_SCIENCES,
];

const constants = {
  MILISECONDS_IN_SECOND,
  MILISECONDS_IN_DAY,
  TWENTY_FOUR_HOURS,
  TRANSITION_TIMEOUT,
  MAX_SCORE,
  IMAGE_URL,
  AGORA_APP_ID,
  SECONDS_PER_MINUTE,
  SECONDS_PER_HOUR,
  classArray,
  DEBUG,
  GQL_ENDPOINT,
  isDev,
  STRIPE_KEY,
  SUBSCRIPTION_ENDPOINT,
  DIFFICULTY_OPTIONS,
};

export default constants;
