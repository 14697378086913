import { create } from 'zustand';

interface LightboxState {
  index: number;
  setIndex: (index: number) => void;
  openLightbox: (index: number) => void;
  closeLightbox: () => void;
}

const useLightboxState = create<LightboxState>()(set => ({
  index: -1,
  setIndex: (index: number) => set({ index }),
  openLightbox: (index: number) => set({ index }),
  closeLightbox: () => set({ index: -1 }),
}));

export default useLightboxState;
